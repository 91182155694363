<template>
	<div>
		<el-breadcrumb separator="/">
			<el-breadcrumb-item  :to="{ path: item.path}" v-for="(item,index) in breadcrumbList" :key="index"> {{ $lang(item,'title') }}</el-breadcrumb-item>
			
		</el-breadcrumb>
	</div>
</template>

<script>
	export default{
		props:{
			arrList:{
				type:Array,
				default:[]
			}
		},
		data(){
			return{
				breadcrumbList:[{
					titleEnUs:"index",
					titleZhCn:"首页",
					path:"/"
				}]
			}
		},
		created() {
		    this.breadcrumbList=[...this.breadcrumbList,...this.arrList]
			
		}
	}
</script>

<style>
</style>
