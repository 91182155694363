<template>
	<div>
		<el-dialog :visible.sync="visible" class="dialog">
			<el-form :model="editModel" :rules="rules" ref="editModel" label-width="70px" class="form">
			  <el-form-item   :label=" $t('newadd.t.姓名')"  prop="realname">
			    <el-input v-model="editModel.realname"></el-input>
			  </el-form-item>
			  <el-form-item  prop="phone"  :label=" $t('newadd.t.电话')" >
				  <div class="e-flex e-row-center " style="line-height: 0;">
					  <country-code-selector :countryCode.sync="editModel.countryvalue" ></country-code-selector>
					  <el-input v-model="editModel.phone" style="width: 250px"></el-input>
				  </div>
			  </el-form-item>
		
			  
			  
			  <el-form-item :label="$t('newadd.t.邮编')" >
			  	<el-input v-model="editModel.zipCode"></el-input>
			  </el-form-item>
			  
			  <el-form-item   prop="realname "  :label=" $t('newadd.t.地址')" >
			    <span v-if="editModel.country">{{ editModel.country }}</span>
			    <span v-if="editModel.province"> / {{ editModel.province }}</span>
			    <span v-if="editModel.city"> / {{ editModel.city }}</span>
			    <span v-if="editModel.county"> / {{ editModel.county }}</span>
			    <el-button type="text" style="margin-left: 15px" @click="handleSelectAddressClick">{{$t('newadd.t.选择')}}</el-button>
			  </el-form-item>
			  
			  <el-form-item class="full"  :label=" $t('newadd.t.详细地址')" >
			  	<el-input type="textarea" v-model="editModel.address" />
			  </el-form-item>
			</el-form>
			
			<span slot="footer" class="dialog-footer">
			  <el-button @click="visible = false">{{$t('newadd.t.取消')}}</el-button>
			  <el-button type="primary" @click="handleUpdate" :loading="loading">{{$t('newadd.t.确认')}}</el-button>
			</span>

		</el-dialog>
		
		
		<address-select ref="AddressSelect" @confirm="handleAddressSelect"/>
		
	</div>
</template>

<script>
	
	
	import AddressSelect from "@/components/AddressSelect";
	import countryCodeSelector from 'vue-country-code-selector';
	export default {
		components: {
			AddressSelect,
			countryCodeSelector
		},

		data() {
			return {
				editModel: {},
				visible: false,
				
				loading:false,
				
				  rules: {
				          realname: [
				            { required: true, message: '请输入姓名', trigger: 'blur' },
				            
				          ],
				       phone: [
				            { required: true, message: '请输入电话', trigger: 'blur' },
				            
				          ],
				        }
				
			}
		},
		methods: {
			//  handleAdd() {
					// this.$req.post("/client/im/user/address/add", this.editModel).then(async res => {
					//   this.address = await this.$store.dispatch("GetUserAddress") || {};
					//   this.$emit("change")
					//   this.visible = false;
					// })
			//  },
			 handleUpdate() {
				 var obj1=this.editModel;
				 var obj2 = Object.assign({}, obj1);
				 obj2.phone ='+' + this.editModel.countryvalue + '#' +obj2.phone;
				this.$req.post("/client/im/user/address/update", obj2).then(res => {
				
				
				
				  this.visible = false;
				})
			 },
			 handleSelectAddressClick() {
			   this.$refs.AddressSelect.show();
			 },
			 handleAddressSelect(obj) {
			   let {country, province, city} = obj;
			   this.editModel.country = this.$lang(country, "name");
			   this.editModel.province = this.$lang(province, "name");
			   this.editModel.city = this.$lang(city, "name");
			   let code = country.ID + "," + province.ID
			   if (country.ID == 86) {
			     code += ("," + city.ID);
			     this.editModel.city = this.$lang(city, "name");
			   }
			   this.editModel.code = code;
			 },
		},
	}
</script>

<style scoped lang='scss'>
	.el-dialog  {
		
			width: 500px!important;
	

		
	}
	.el-form-item .el-form-item {
		margin-bottom: 20px!important;
	}
</style>
