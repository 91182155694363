<template>
	<div class="wrap">
		

		<!--地址-->
		<el-card class="box-card" shadow="never">
			<div slot="header">{{$t("newadd.t.默认地址")}}	：</div>
			<el-row>
				<address-com @change="post(true)"  :address="address"  v-if="address.id"/>
			</el-row>
		</el-card>


		<el-card class="box-card" shadow="never">
			<div slot="header">
				{{$t("goodsPay.BrandName")}}
			</div>
			<div>
				<el-table :data="tableData" style="width: 100%">
					<el-table-column prop="date" :label="$t('goodsPay.goodsInfo')" width="500">
						<template v-slot="scope">
							<el-row>
								<el-col :span="8">
				
									<el-image style="width: 100px; height: 100px" :src="$req.getFileUrl( scope.row.pic) "
										fit="fit">
									</el-image>
				
								</el-col>
								<el-col :span="16">
									<el-row>
										{{$lang(scope.row, "title")}}
									</el-row>
									<el-row>
										{{ $t("goodsPay.size") }}：{{$lang(scope.row, "codeName")}}
									</el-row>
								</el-col>
							</el-row>
						</template>
					</el-table-column>
					<el-table-column prop="name" :label="$t('goodsPay.goodsType')" width="180">
						<template v-slot="scope">
							{{$lang(scope.row, "codeName")}}
						</template>
					</el-table-column>
					<el-table-column prop="address" :label="$t('goodsPay.goodsPrice')">
						<template v-slot="scope">
							￥{{scope.row.price | toFix}}
				
						</template>
					</el-table-column>
					<el-table-column prop="address" label="数量" :label="$t('goodsPay.goodsNum')">
						<template v-slot="scope">
							{{scope.row.gooodnum}}
						</template>
					</el-table-column>
					<el-table-column prop="address" :label="$t('goodsPay.goodsSubtotal')">
						<template v-slot="scope">
							￥{{scope.row.price*scope.row.gooodnum | toFix}}
						</template>
					</el-table-column>
				</el-table>

			</div>
			<!-- <el-row style="padding: 20px 10px;">
				<div style="color: #5C5E62;font-weight: bold;margin-bottom: 20px;">  {{$t("newadd.t.保险赔付")}}</div>
				<el-row type="flex" justify="space-between">
					<el-col :span="23">
						<i class="el-icon-truck"></i>
						<span style=";margin-left: 20px;">
							运险费：<span style="color: red;font-size: 25px">{{elseInfo.insureMoney | toFix}}</span>元
						</span>
					</el-col>
					<el-col :span="1">
						<el-switch v-model="isepx" @change="changeIsepx"></el-switch>
					</el-col>
				</el-row>
			</el-row> -->

		</el-card>

		<el-card class="box-card" shadow="never">
			<el-row type="flex" align="middle" slot="header">
				<el-col :span="3">
					<el-switch :active-text=" $t('newadd.t.我要开票')" v-model="isinvoice"></el-switch>
				</el-col>
				<el-col :span="1" v-if="isinvoice">
					<i class="el-icon-edit-outline hover" style="color: #3A8EE6;" @click="goInvoice"></i>
				</el-col>
			</el-row>

			<el-row style="margin-top: 20px;">
				<el-form class="demo-ruleForm" label-width="100px" label-position="left">
					<el-form-item :label=" $t('newadd.t.订单备注')">
						<el-input :placeholder=" $t('newadd.t.订单备注')" v-model="remark" type="textarea"></el-input>
					</el-form-item>
				</el-form>
			</el-row>


			<el-row type="flex" justify="end">
				<div class="e-margin-bottom-10">
				{{$t("newadd.t.保险金额")}}	:{{elseInfo.insureMoney | toFix}}元
				</div>
			</el-row>

			<el-row type="flex" justify="end">
				<div class="e-margin-bottom-10">
				{{$t("newadd.t.运费")}}		:{{elseInfo.freightMoney | toFix}}元
				</div>
			</el-row>

			<el-row type="flex" justify="end">
				<div class="e-margin-bottom-10">
				{{$t("newadd.t.商品总金额")}}		:{{elseInfo.goodsMoney | toFix}}元
				</div>
			</el-row>

			<el-row type="flex" justify="end">
				<div class="e-margin-bottom-10">
				{{$t("newadd.t.一笔款金额")}}		:{{elseInfo.moneyFirst | toFix}}元
				</div>
			</el-row>

			<el-row type="flex" justify="end">
				<div class="e-margin-bottom-10">
				{{$t("newadd.t.原始金额")}}		:{{elseInfo.moneyOriginal | toFix}}元
				</div>
			</el-row>


			<el-row type="flex" justify="end">
				<div class="e-margin-bottom-10">
					{{$t("newadd.t.二笔款金额")}}	:{{elseInfo.moneySecond | toFix}}元
				</div>
			</el-row>

			<el-row type="flex" justify="end">
				<div class="e-margin-bottom-10 " style="color: #FF0000;">
				{{$t("newadd.t.应付总金额")}}		:{{elseInfo.money | toFix}}元
				</div>
			</el-row>

			<el-row type="flex" justify="end" align='middle'>
				<e-col style="margin-right: 20px;">
					<el-select v-model="payType" size="small">
						<el-option value="payAll" :label=" $t('newadd.t.全款支付')"></el-option>
						<el-option value="payPath" :label=" $t('newadd.t.分期支付')"></el-option>
					</el-select>
				</e-col>
				<e-col>
					<el-button type="primary" size="medium" @click="sumbitOrder(false)">
					
					{{$t("newadd.t.提交订单")}}	
					</el-button>
				</e-col>
			</el-row>



		</el-card>

	</div>
</template>

<script>
	import AddressCom from "@/components/Address";
	import breadcrumb from '@/components/breadcrumb';
	import {
		common
	} from "@/api/common";
	import {
		addStandard
	} from "@/api/order";
	import {
		defaultAddress,
		getUserInfo
	} from "@/api/User";

	import {
		mapState,
		mapMutations
	} from "vuex";
	import {getToken,save,get,changemodile} from "@/utils/auth";

	export default {
		components: {
			breadcrumb,
			AddressCom

		},
		computed: {
			...mapState(['params'])
		},
		watch: {
			isepx: {
				immediatel: true,
				handler(v) {
					this.jisuan()
				}
			}
		},
		data() {
			return {
				payType: 'payAll',
				arrList: [{
					titleEnUs: "index",
					titleZhCn: "工品商城",
					path: "/mall"
				}, {
					titleEnUs: "index",
					titleZhCn: "产品信息",

				}, {
					titleEnUs: "index",
					titleZhCn: "商品支付",
					path: ""
				}],
				address: {

				},
				elseInfo: {

				},
				tableData: [],
				expmoeny: "",
				isepx: false,
				isinvoice: false,
				remark: "",
				allmenony: "",
				pocketmenony: "",
				epxmenony: "",
				realMenony: "",
				iselse: false,
				freightInsurance: 0,
				goodsId: [],
				numId: []

			}
		},

		
		async mounted() {
			console.log(this.params,777)
			this.tableData = this.params;
			await this.getAddress()
			this.jisuan()
			this.sumbitOrder(true)

		},
		methods: {
			changeIsepx() {
				this.sumbitOrder(true)
			},
			goInvoice() {
				this.$router.push("/sentInvoice")
			},

			//获取默认地址
			
			//获取默认地址
			getAddress() {
				return new Promise((resolve, reject) => {
					getUserInfo().then(res => {
							var data = changemodile(res.phone);
							res.address.phone = data.phone;
							res.address.countryvalue = data.code;
					        this.address = res.address;
						resolve(res)
					}).catch(err => {
			
					})
				})
			
			},
			jisuan() {

				var shijiMoneny = Number(this.allmenony) + Number(this.isepx ? this.freightInsurance : 0);
				this.realMenony = shijiMoneny.toFixed(2)


			},
			// 提交
			sumbitOrder(onlyCalc) {
				console.log(this.tableData)
				var goodsId = this.tableData[0].id;
				var insure = this.isepx ? 1 : 0;
				var num = this.tableData[0].gooodnum;
				var addressId = this.address.id;
				var payType = this.payType == "payAll" ? 1 : 2;
				var remark = this.remark;
				var boxSize = this.tableData[0].size;
				var sukId = this.tableData[0].id;
				var requireInvoice = this.isinvoice;
				addStandard(onlyCalc, addressId, goodsId, insure, num, payType, remark, boxSize, sukId, requireInvoice)
					.then(res => {
						this.elseInfo = res;
						if (!onlyCalc) {
							this.$message({
								message: '下单成功',
								type: 'success',
								onClose: (res) => {
									this.$router.push({
										path: "/orderadmin"
									})
								}
							});
						}
					}).catch(err => {

					})
			}
		}
	}
</script>

<style lang="scss">
	html {
		//background: #f1f1f1;
	}

	.wrap {
		width: 1200px;
		margin: 60px auto;
		color: #555;
		font-size: 14px;
	}

	.box-card {
		margin-top: 20px;
	}

	.el-icon-truck {

		font-size: 30px;
	}
</style>
