<template>
	<div class="address-wrapper">
		<el-card class="card" shadow="hover">
			<div>
				<el-row>
					<el-col :span="5">{{ address.realname }}</el-col>
					<el-col :span="15">{{ address.phone }}</el-col>
					<el-col :span="4">
						<i class="el-icon-edit-outline hover" @click="handleEditClick"></i>
					</el-col>
				</el-row>
		
				<el-row class="detail">
					<span>{{ address.country }}</span>
					<span>{{ address.province }}</span>
					<span>{{ address.city }}</span>
					<span>{{ address.address }}</span>
				</el-row>
			</div>
		</el-card>
		
		
		<addressDetail ref="chilren" v-if="show"></addressDetail>
	</div>
</template>

<script>
	import addressDetail from './addressDetail';
	
	export default {
	    components: {
			addressDetail
		},
		data() {
			return {
				editModel:{
					
				},
				show:false
			}
		},
		props: {
			address: {
				type: Object,
				default:()=>{}
			}
		},
		mounted() {
			
			
		},
		
		methods: {
			handleEditClick() {
				this.show=true;
				setTimeout(()=> {
					this.$refs.chilren.editModel=this.address;
					this.$refs.chilren.visible=true;
				}, 10);
			},
		},
	}
</script>

<style scoped lang='scss'>
	.address-wrapper {
		.card {
			color: #666;
			width: 400px;

			.detail {
				margin-top: 14px;
				word-break: break-all;

				span+span {
					margin-left: 5px;
				}
			}
		}

		
	}
</style>
